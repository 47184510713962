import React from 'react'
import counterIconOne from '../../assets/images/icon/counter-icon-1.svg'
import counterIconTwo from '../../assets/images/icon/counter-icon-2.svg'
import counterIconThree from '../../assets/images/icon/counter-icon-3.svg'
import counterIconFour from '../../assets/images/icon/counter-icon-4.svg'
import CounterUpCom from '../../lib/CounterUpCom'

function CryptoInNumbers({ style }) {
  return (
    <>
      <section
        className="appie-counter-area pt-90 pb-190"
        id="counter"
        style={style}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title">
                <h3 className="appie-title">Krypto och defi i siffror</h3>
                <p>
                  Med får du full exponering mot alla delar inom krypto
                  med möjligheter till fantastisk avkastning!
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="counter-content">
                  <div className="icon">
                    <img src={counterIconOne} alt="" />
                  </div>
                  <h3 className="title">
                    <span className="counter-item">
                      <CounterUpCom endValue={1780} sectionSelect="counter" />
                    </span>
                    %
                  </h3>
                  <p>Tillväxt i Defi</p>
                  <p>år 2021</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 item-2 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="counter-content">
                  <div className="icon">
                    <img src={counterIconTwo} alt="" />
                  </div>
                  <h3 className="title">
                    <span className="counter-item">
                      <CounterUpCom endValue={135} sectionSelect="counter" />
                    </span>
                    %
                  </h3>
                  <p>Bitcoins tillväxt per år</p>
                  <p>sedan 2010</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 item-3 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="counter-content">
                  <div className="icon">
                    <img src={counterIconThree} alt="" />
                  </div>
                  <h3 className="title">
                    <span className="counter-item">
                      <CounterUpCom endValue={310} sectionSelect="counter" />
                    </span>
                    k+
                  </h3>
                  <p>Svenskar köpte bitcoin</p>
                  <p>Senaste året</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 item-4 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="800ms"
              >
                <div className="counter-content">
                  <div className="icon">
                    <img src={counterIconFour} alt="" />
                  </div>
                  <h3 className="title">
                    <span className="counter-item">
                      <CounterUpCom endValue={100} sectionSelect="counter" />
                    </span>
                    x
                  </h3>
                  <p>Förväntad tillväxt av defi</p>
                  <p>till år 2026</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CryptoInNumbers
